/**
 * Author: Francisco
 * Date: 06/10/2021
 */

import React from 'react';
import { Helmet } from 'react-helmet';

import { SettingsContent } from '../components/ui/SettingsContent';
import { Layout } from '../components/ui/Layout';



const Settings = () => {
    

    return (
        <>
            <Layout>
                <Helmet>
                    <title>Ajustes | Xplora</title>
                </Helmet>
                < div style={{ paddingTop: '4rem'}}/>
               
                    <SettingsContent />
                
                
                
            </Layout>
        </>
    )
}

export default Settings;