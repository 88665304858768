
//Third-party packages
import React from 'react'
import { useDispatch } from 'react-redux';
import { startNewBudgetAndAssociatedLines } from '../../redux/actions/sales';


//Custom imports
import { uiManageModalNotification } from '../../redux/actions/ui';
import { Content } from './Content';


/**
 * 
    Author: Francisco
    Date: 13-12-2021
 */
export const ConfirmAddInvoice = ({ payload, setSubmitting }) => {
    
    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions
    setSubmitting( false )
    const handleSuccess = () => {
        dispatch( uiManageModalNotification()); //we close the confirmation modal
        dispatch(startNewBudgetAndAssociatedLines( payload, setSubmitting )); //We dispatch the action passing as a parameter the user id
    }

    //The HTML is being rendered
    return (
            <Content 
                title={ '¿Estás seguro de que quiere añadir una nueva venta?' }
                text={ 'Se agregará una nueva venta al sistema' }
                action={ handleSuccess }
            />
    )
}
