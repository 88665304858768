
//Third-party packages
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startDeleteCustomer } from '../../redux/actions/clients';


//Custom imports
import { uiManageModalNotification } from '../../redux/actions/ui';
import { Content } from './Content';


/**
 * 
    Author: Francisco
    Date: 29-09-2021
 */
export const ConfirmDeleteClient = () => {

    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions
    const { active } = useSelector(state => state.client) //we get the users from the state 


    const handleSuccess = () => {
        dispatch( uiManageModalNotification()); //we close the confirmation modal
        dispatch(startDeleteCustomer(active.PkClientes)); //We dispatch the action passing as a parameter the user id
    }

    //The HTML is being rendered
    return (
            <Content 
                title={ '¿Quieres borrar el cliente?' }
                text={ 'El cliente quedará eliminado definitivamente del sistema' }
                action={ handleSuccess }
            />
    )
}
