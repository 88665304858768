
//Third-party packages
import React from 'react'
import { useDispatch } from 'react-redux';
import { startFacturar } from '../../redux/actions/sales';


//Custom imports
import { uiManageModalNotification } from '../../redux/actions/ui';
import { Content } from './Content';


/**
 * 
    Author: Francisco
    Date: 04-02-2021
 */
export const ConfirmFacturar = () => {
    
    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions
    const handleSuccess = () => {
        dispatch(uiManageModalNotification())
        dispatch(startFacturar())
    }

    //The HTML is being rendered
    return (
            <Content 
                title={ '¿Estás seguro de que quieres facturar la venta? Esta acción es irreversible' }
                action={ handleSuccess }
            />
    )
}
