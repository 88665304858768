import React, { Fragment, useEffect, useState } from 'react';

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Menu,
    MenuItem,
    Typography,
    useMediaQuery,
    useTheme
  } from '@material-ui/core';
import { useStyles } from '../../styles/useSettingsStyles';
import { useDispatch, useSelector } from 'react-redux';
import { startDownloadTerms, startLoadingSettings, startDownloadContratoTratamientoDatos } from '../../redux/actions/settings';
import { Apartment, Edit } from '@material-ui/icons'; 
import { uiManageModalCollectionTypes } from '../../redux/actions/ui';
import { TransitionsModal } from './TransitionModal';
import { startContentModalSettings, startContentModalDetailVoucher } from '../../redux/actions/contentModal';
import UploadTerms from '../settings/uploadTerms';
import UploadContratoTratamientoDatos from '../settings/uploadContratoTratamientoDatos';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Spinner } from './Spinner';

export const SettingsContent = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme()
    useEffect(() => {
        dispatch( startLoadingSettings() )
    }, [ dispatch ]);
    const [openDialog, setOpenDialog] = useState(false)
    const [openDialog2, setOpenDialog2] = useState(false)
    const { loading } = useSelector( state => state.ui );

    
    const settings = useSelector(state => state.settings);
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));

    const handleEdit = () => {
        dispatch( uiManageModalCollectionTypes() );
        dispatch( startContentModalSettings() );

    }

    const handleDownload = () => {
        dispatch( startDownloadTerms() );
    }
    
    const handleDownloadContrato = () => {
        dispatch( startDownloadContratoTratamientoDatos() );
    }

    const handleOpenUpload = () => {
        setOpenDialog( true );
    }
    const handleOpenUploadContrato = () => {
        setOpenDialog2( true );
    }

    const handleOpenModalVoucher = () => {
        dispatch( uiManageModalCollectionTypes() );
        dispatch( startContentModalDetailVoucher( {
                texto: settings.TextoConfigurableInformeVoucher,
                field: 'TextoConfigurableInformeVoucher'
            }));
    }

    const handleOpenModalFactura = () => {
        dispatch( uiManageModalCollectionTypes() );
        dispatch( startContentModalDetailVoucher( {
                texto: settings.TextoConfigurableInformeFactura,
                field: 'TextoConfigurableInformeFactura'
            }));
    }

    const handleOpenModalTransferencias = () => {
        dispatch( uiManageModalCollectionTypes() );
        dispatch( startContentModalDetailVoucher( {
                texto: settings.InformacionTransferencias,
                field: 'InformacionTransferencias'
            }));
    }

    const handleOpenModalPresupuesto = () => {
        dispatch( uiManageModalCollectionTypes() );
        dispatch( startContentModalDetailVoucher( {
                texto: settings.TextoConfigurableInformePresupuesto,
                field: 'TextoConfigurableInformePresupuesto'
            }));
    }
    
    

    return (
        <>
        {
            loading ?
            <Spinner />
            :
            <Card
            style={{
                width: '80vw'
            }}
                >
                <CardHeader
                    title={
                        <Typography gutterBottom variant={ !matchesXS ? "h1" : 'h2'} >
                            Datos de { `${ settings.NombreEmpresa }` }
                        </Typography>
                    }
                    avatar={ <Apartment /> }
                />
                <Divider />
                <CardContent>
                    <Grid container>
                    <Grid item container classes={{ root: classes.containerFeautures }}>
                        <Grid item container classes={{ root: classes.wrapRowSettings }}>
                            <Grid item>
                                <Typography variant={ matchesXS ? 'body2' : 'body1' }>Nombre Empresa</Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant={ matchesXS ? 'body2' : 'body1' } >{ settings.NombreEmpresa }</Typography>
                            </Grid>

                        </Grid>
                        <Divider />
                        <Grid item container classes={{ root: classes.wrapRowSettings }}>
                            <Grid item>
                                <Typography variant={ matchesXS ? 'body2' : 'body1' }>Razón Social</Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant={ matchesXS ? 'body2' : 'body1' }>{ settings.RazonSocial }</Typography>
                            </Grid>

                        </Grid>

                        <Divider />
                        <Grid item container classes={{ root: classes.wrapRowSettings }}>
                            <Grid item>
                                <Typography variant={ matchesXS ? 'body2' : 'body1' }>Nombre Serie Facturación</Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant={ matchesXS ? 'body2' : 'body1' }>{ settings.NombreSerieFacturacion }</Typography>
                            </Grid>

                        </Grid>

                        <Divider />
                        <Grid item container classes={{ root: classes.wrapRowSettings }}>
                            <Grid item>
                                <Typography variant={ matchesXS ? 'body2' : 'body1' }>Último Número de Factura</Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant={ matchesXS ? 'body2' : 'body1' }>{ settings.UltimoNumeroFactura }</Typography>
                            </Grid>

                        </Grid>


                    </Grid>
                    </Grid>
                </CardContent>
                <Divider />

                {
                    !matchesLG
                    ?
                    (
                        <Box
                                sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2,
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    style={{
                                        marginRight: '0.5rem',
                                        fontWeight: 700
                                    }}
                                    onClick={ handleOpenModalVoucher }
                                >
                                Editar Texto Voucher
                                </Button>

                                <Button
                                    variant="outlined"
                                    style={{
                                        marginRight: '0.5rem',
                                        fontWeight: 700
                                    }}
                                    onClick={ handleOpenModalFactura }
                                >
                                Editar Texto Factura
                                </Button>

                                <Button
                                    variant="outlined"
                                    style={{
                                        marginRight: '0.5rem',
                                        fontWeight: 700
                                    }}
                                    onClick={ handleOpenModalPresupuesto }
                                >
                                Editar Texto Presupuesto
                                </Button>

                                <Button
                                    variant="outlined"
                                    style={{
                                        marginRight: '0.5rem',
                                        fontWeight: 700
                                    }}
                                    onClick={ handleOpenModalTransferencias }
                                >
                                Editar Texto Informe Transferencia
                                </Button>

                                <Button
                                    variant="outlined"
                                    style={{
                                        marginRight: '0.5rem',
                                        fontWeight: 700
                                    }}
                                    onClick={ handleEdit }
                                >
                                Editar Datos Empresa
                                </Button>

                                <Button
                                    variant="outlined"
                                    style={{
                                        marginRight: '0.5rem',
                                        fontWeight: 700
                                    }}
                                    onClick={ handleDownload }
                                >
                                    Descargar Políticas
                                </Button>

                                <Button
                                    variant="outlined"
                                    style={{
                                        marginRight: '0.5rem',
                                        fontWeight: 700
                                    }}
                                    onClick={ handleDownloadContrato }
                                >
                                    Descargar Contrato tratamiento datos
                                </Button>

                                <Button
                                    variant="outlined"
                                    style={{
                                        marginRight: '0.5rem',
                                        fontWeight: 700
                                    }}
                                    onClick={ handleOpenUpload }
                                >
                                    Subir Políticas
                                </Button>

                                <Button
                                    variant="outlined"
                                    style={{
                                        marginRight: '0.5rem',
                                        fontWeight: 700
                                    }}
                                    onClick={ handleOpenUploadContrato }
                                >
                                    Subir Contrato Agencias
                                </Button>

                            </Box>
                    )

                    :

                    (
                    <Box
                            sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2,
                            }}
                        >
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                            <Fragment>
                                <Button 
                                    variant="outlined" 
                                    {...bindTrigger(popupState)}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >
                                    Opciones
                                </Button>
                                    <Menu {...bindMenu(popupState)}>
                                        <MenuItem onClick={ handleOpenUpload }>
                                            <FileCopyIcon />
                                            <Typography variant='h4'>Subir Políticas</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={ handleOpenUploadContrato }>
                                            <FileCopyIcon />
                                            <Typography variant='h4'>Subir Contrato agencias</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={ handleDownload }>
                                            <CloudDownloadIcon />
                                            <Typography variant='h4'>Descargar Políticas</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={ handleDownloadContrato }>
                                            <CloudDownloadIcon />
                                            <Typography variant='h4'>Descargar Contrato Tratamiento Datos</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={ handleEdit }>
                                            <Edit />
                                            <Typography variant='h4'>Editar Datos Empresa</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={ handleOpenModalFactura }>
                                            <Edit />
                                            <Typography variant='h4'>Editar Texto Factura</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={ handleOpenModalPresupuesto }>
                                            <Edit />
                                            <Typography variant='h4'>Editar Texto Presupuesto</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={ handleOpenModalTransferencias }>
                                            <Edit />
                                            <Typography variant='h4'>Editar Texto Transferencias</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={ handleOpenModalVoucher }>
                                            <Edit />
                                            <Typography variant='h4'>Editar Texto Voucher</Typography>
                                        </MenuItem>
                                    </Menu>
                            </Fragment>
                            )}
                        </PopupState>
                    </Box>
                    )
                }
                
                </Card>
        }
        

        <TransitionsModal />
        <UploadTerms openDialog={ openDialog } setOpenDialog={ setOpenDialog }/>
       
        <TransitionsModal />
        <UploadContratoTratamientoDatos openDialog={ openDialog2 } setOpenDialog={ setOpenDialog2 }/>
        </>
    )
}
  
