
//Third-party packages
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, Box, Modal, Fade, useMediaQuery, useTheme, Dialog, DialogContent} from '@material-ui/core'
//Custom imports
import { uiManageModalCollectionTypes } from '../../redux/actions/ui';
import { startLogOutContentModal } from '../../redux/actions/contentModal';


/**
 * 
 * Author: Francisco
 * Date: 29-09-2021
 */



export const TransitionsModal = () => {
    const { modal } = useSelector(state => state.modal); //we get the modal state: true or false
    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions 
    const theme = useTheme(); // useTheme hook to get the theme props
    const handleClose = () => {
        dispatch(uiManageModalCollectionTypes()); //close de collection type modal (CRUD modal)
        dispatch(startLogOutContentModal())
    }
    const matchesMD = useMediaQuery(theme.breakpoints.down('md')); //boolean variable: true when screen size is < md and false if it is greater
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs')); //boolean variable: true when screen size is < xs and false if it is greater
    
    const { content } = useSelector(state => state.contentModal); // we get the content from the contentModal reducer
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    //We define the styles of our modal
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      maxHeight: '85vh',
      transform: 'translate(-50%, -50%)',
      width: matchesXS ? 350 : (matchesMD ? 500 : 1000),
      height: matchesXS ? 750 : (matchesMD ? 650 : 800),
      bgcolor: 'background.paper',
      boxShadow: 24,
      elevation: 4,
      overflow: 'auto',
      zIndex: 998,
    };

    
    /**
     * HTML Modal Container
     */
  return (
    <div>
      {
        !matchesMD
        ?
            <Modal
            open={modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
              
            }}
          >
          <>
                
            <Fade 
              in={modal}  
            >
              <>
                
                  <Box sx={style}>
                    { content }
                  </Box>
              </>
            </Fade>
          </>
          </Modal>
        :
      
          <Dialog
            fullScreen={ matchesMD && fullScreen}
            open={modal}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              {content}
            </DialogContent>
          </Dialog>
        }
    </div>
  );
}