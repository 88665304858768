
//Third-party imports
import React from 'react'
import {useSelector } from 'react-redux';
import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core'

import { useFormStyles } from '../../styles/formStyles';
import { loadGridSupplier } from '../../helpers/loadGrid';
/**
 * 
 * Author: Francisco
 * Date: 30-09-2021
 */
export const ContentModalSupplierDetail = () => {
    const classes = useFormStyles(); //we load the styles from the useForm arrow function
    const { active } = useSelector(state => state.supplier)
    const { data } = loadGridSupplier(); 
    //HTML Grid Container
    return (
        <Grid item style={{ padding: '2rem'}}>
            <Grid container direction='column'>
                
                <Grid item classes={{root: classes.container }}>
                    <Typography variant='h3'>Proveedor { active.Nombre }</Typography>
                </Grid>

                {
                        data.map( element => {
                            return(
                                <Grid item  classes={{root: classes.container }}>
                                    <TextField
                                        fullWidth
                                        name={element.name}
                                        size='small'
                                        classes={{ root: classes.inputText }}
                                        id={`outlined-basic-${element.name}`}  
                                        label={ element.label }
                                        variant='outlined'
                                        disabled='true'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    { element.startAdornment }
                                                </InputAdornment>
                                            ),

                                            classes: {
                                                input: classes.resize
                                            }
                                        }}
                                        value={active[element.name]}
                                        multiline={ element.name === 'Observaciones' ? true : false}
                                        maxRows={ element.name === 'Observaciones' ? 10 : undefined }
                                        minRows={ element.name === 'Observaciones' ? 5 : undefined }
                                        
                                    />
                                </Grid>
                            )
                        })
                    }



            </Grid>
        </Grid>
    )
}
