
//Third-party packages
import React from 'react'
import { AppBar as MuiAppBar, IconButton, Toolbar, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { styled } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux'

//Custom imports
import barsSolid from '../../images/bars-solid.svg';
import { uiManageModal } from '../../redux/actions/ui'
import { MenuPopUp } from './MenuPopUp';
/**
    Author: Francisco
    Date: 22-09-2021
 */

const drawerWidth = 240;

/**
 * We define the AppBar styles considering that must be above the menu
 */
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));


  const useStyles = makeStyles( () => ({
    imgbar:{
        width: '1.5rem',
        height: '1.5rem',
    },
}))

export const Header = () => {
    const classes = useStyles(); //we load the styles from the useForm arrow function
    const { menuState } = useSelector(state => state.ui); //we get the menu state from the ui reducer
    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions 
    const theme = useTheme()//we get the theme from the useTheme() hook
    const matchesXS = useMediaQuery(theme.breakpoints.down('md')); //we define the matchesXS prop to manage the screen size
    const toogleDrawer = () => {    
        dispatch(uiManageModal()); //We change the ui modal state
    }

    

    //HTML is gonna be rendered
    return (
        <AppBar position='absolute' style={{ backgroundColor: '#FFF6F1'}}>
                <Toolbar
                    sx={{
                        pr: '24px',
                      }}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                            
                    <IconButton
                            edge='start'
                            color='inherit'
                            arial-label='open drawer'
                            onClick={ toogleDrawer }
                            sx={{
                                ...( menuState && { display: 'none' } )
                            }}
                        >
                            <img src={barsSolid} alt="bars" className={ classes.imgbar }/>
                    </IconButton>
                    <img src='https://xplorariviera.com/wp-content/uploads/2021/12/xplora_riviera-1-3.png' alt='logo' width={'65'}  style={{ height: 'auto'}} />
                    </div>
                    {
                        !matchesXS && <MenuPopUp />
                    }
                    
                </Toolbar>
        </AppBar>
    )
}
