
//Third-party packages
import React from 'react';
import { 
    CssBaseline,
    Box,
    Toolbar

} from '@material-ui/core';



//Custom imports
import { Header } from './Header';
import { DrawerNav } from './Drawer';
import { styled } from '@material-ui/styles';

/**
    Author: Francisco
    Date: 22-09-2021
 */


    const DashboardLayoutRoot = styled('div')(({ theme }) => ({
        display: 'flex',
        flex: '1 1 auto',
        maxWidth: '100%',
        paddingTop: 64,
      }));


export const Layout = ({ children }) => {
    /**
     * The layout component. We import all the components: Header, Drawer and Toolbar 
     */
    return (
    <Box sx={{ display: 'flex'}} style={{ backgroundColor: 'white'}}>
            <CssBaseline />

            <Header />
            
            <DrawerNav />
            
            <DashboardLayoutRoot>
                <Toolbar />
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1 1 auto',
                        flexDirection: 'column',
                        width: '100%',
                        minHeight: '100vh',
                        marginBottom: '8rem',
                    }}
                    >
                    {children}
                    </Box>
            </DashboardLayoutRoot>
        
       

        </Box>
    )
}
