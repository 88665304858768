import { Field } from 'formik'
import React from 'react'
import { FormControlLabel } from '@material-ui/core';
import { MaterialUISwitch } from '../../styles/switchStyles';

export const SwitchMUI = ({ label, name, values, setFieldValue }) => {
    const handleChange = () => {
        setFieldValue(name, !values[name])
    }
    return (
        <FormControlLabel
            control={
            <Field
                label={`${ label }`}
                name={`${ name }`}
                component={MaterialUISwitch}
                checked={ values[name] }
                onChange={ handleChange }
                value={values[name]}
            />
            }
            label={`${ label }`}
        />
    )
}
