import { Box, Button, Container, FormControl, Grid, IconButton, InputBase, InputLabel, ListItemIcon, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { Layout } from '../../../components/ui/Layout';
import { useInvoiceStyles } from '../../../styles/invoice';
import Autocomplete from '@mui/material/Autocomplete';
import { actionAddCustomer, actionAddFreeOrderLineToOrder, actionAddInvoice, actionAddOrderLineToOrder, actionEditOrderLineFromState } from '../../../redux/actions/actions';
import { TransitionsModal } from '../../../components/ui/TransitionModal';
import { DeleteOutlineRounded } from '@material-ui/icons';
import { startDeleteOrderLine, startLogOutSalesCleaning } from '../../../redux/actions/sales'
import { Field, Form, Formik } from 'formik';
import { validationSchemaBudget } from '../../../utils/schemaValidationForm';

import { LocalizationProvider } from '@mui/x-date-pickers'

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'

import { NotificationModal } from '../../../components/ui/NotificationModal';
import { finishSuccess, startLogoutUI } from '../../../redux/actions/ui';
import { ROLADMIN, ROLREVENDEDOR, ROLVENDEDOR } from '../../../utils/constants';
import { startGatheringDataFromServicesReseller } from '../../../redux/actions/supplierServices';
import { useFormStyles } from '../../../styles/formStyles';
import esLocale from 'date-fns/locale/es'
import Swal from 'sweetalert2';
import { startLogOutCleanSuppliers } from '../../../redux/actions/suppliers';
import { startLogOutActiveRate } from '../../../redux/actions/exchangeRate';

import { startLoadingClients } from '../../../redux/actions/clients';
import { CustomizedSelect } from '../../../components/ui/CustomizedSelect';
import { loadLanguages } from '../../../helpers/loadLangauges';
import { loadCurrencies } from '../../../helpers/loadCurrencies';

import moment from 'moment-timezone';
import { startLoadingComisionistas } from '../../../redux/actions/comisionistas';
import { getSimboloMoneda } from '../../../utils/utils';

moment.tz.setDefault("Europe/Madrid");

const Invoice = () => {
    
    const dispatch = useDispatch();
    const styles = useInvoiceStyles();
    const [value, setValue] = useState('0');
    const [tasas, setTasas] = useState('0');
    const { active: { orderLines }} = useSelector(state => state.invoice);
    const { idAgencia, idRol, id: loggedInUser, moneda: monedaUsuario } = useSelector(state => state.auth);
    const { modal } = useSelector(state => state.modal)
    //const [ searchClientes, debounceSearchResultClientes ] = useDebounce('/clientes?filter[where][Nombre][like]', {start: 'Nombre', end: 'Apellidos', id: "PkClientes"});
    //const [ searchClientesNotAdmin, debounceSearchResultClientesNotAdmin ] = useDebounce(`/clientes?filter[where][UsuarioAlta][like]=${ loggedInUser }&filter[where][Nombre][like]`, {start: 'Nombre', end: 'Email', id: "PkClientes"});
    const theme = useTheme();
    const matchesXS =  useMediaQuery( theme.breakpoints.down('xs'));
    const classes = useFormStyles();
    const { clients } = useSelector(state => state.client);
    const { comisionistas } = useSelector(state => state.comisionistas);
    const languages = loadLanguages();
    const currencies = loadCurrencies()


    useEffect(() => {

        console.log('orderLines');
        console.log(orderLines);

        let total = 
            orderLines.map( line => 
                (+(line?.SuplementoTransporteEuros??0) + +line?.ReservaLineaEuros + +line?.InSituLineaEuros) * +line?.Unidades
            ).reduce( (prev, curr) => { return (prev + curr)}, 0);
        let tasas = 
            orderLines.map( line => 
                (+line?.TasaEuros) * +line?.Unidades
            ).reduce( (prev, curr) => { return (prev + curr)}, 0);
        
        if (idRol===ROLREVENDEDOR && monedaUsuario==='USD') {
                
            console.log("Lineas de venta: ",orderLines);

            total = 
                orderLines.map( line => 
                    ( +(line?.SuplementoTransporteDolares??0) + +line?.ReservaLineaDolares + +line?.InSituLineaDolares) * +line?.Unidades
                ).reduce( (prev, curr) => { return (prev + curr)}, 0);
            tasas = 
                orderLines.map( line => 
                    (+line?.TasaDolares) * +line?.Unidades
                ).reduce( (prev, curr) => { return (prev + curr)}, 0);
        
        }

        setTasas(tasas); // + ' ' + getSimboloMoneda(idRol===ROLREVENDEDOR?monedaUsuario:'EUR'));
        setValue(total); // + ' ' + getSimboloMoneda(idRol===ROLREVENDEDOR?monedaUsuario:'EUR'));

    }, [idRol, orderLines]);
    

    useEffect(() => {
        if( idRol === ROLREVENDEDOR){
            dispatch(startGatheringDataFromServicesReseller( (idAgencia>0)?idAgencia:loggedInUser ))
        }
    }, [ idRol, dispatch, loggedInUser ])


    useEffect(() => {
        dispatch(startLogOutSalesCleaning())
    }, [ dispatch ]);

    useEffect(() => {
        dispatch(startLogoutUI())
    }, [ dispatch ])

    useEffect(() => {
        if( !modal ){
            dispatch(startLogOutCleanSuppliers())
            dispatch(startLogOutActiveRate())
        }
    }, [ modal, dispatch ])


    useEffect(() => {
        dispatch(startLoadingClients())
    }, [ dispatch ]);

    const clientsArray = clients.map ((client) => { return { label: `${client.Nombre} ${client.Apellidos}`, value: client.PkClientes }});
    
    useEffect(() => {
        dispatch(startLoadingComisionistas() )
    }, [ dispatch ]);

    const comisionistasArray = comisionistas.map ((client) => { return { label: `${client.Nombre} `, value: client.PkComisionistas }});
    


    const handleAddClient = () => {
        dispatch(actionAddCustomer(true))
    }

    const handleAddRowService = () => {
       dispatch(actionAddOrderLineToOrder());
    }

    const handleAddRowCustomService = () => {
        dispatch(actionAddFreeOrderLineToOrder())
    }


    const handleRemoveField = (index, e) => {
        if (e) e.stopPropagation();
       dispatch(startDeleteOrderLine( index ))
       dispatch(finishSuccess())
    }

    const handleEditLineState = (index, e) => {
        if (e) e.stopPropagation();
        //if( idRol !== ROLREVENDEDOR)
        dispatch( actionEditOrderLineFromState( orderLines[index], index) ) // Hasta que se pueda editar en el estado como revendedor
     }

    let m = moment().utcOffset(0);
    m.set({hour:0,minute:0,second:0,millisecond:0});
    m.toISOString();
    m.format();

    const initialValues = { 
        FechaPresupuesto: m, //moment(moment().utcOffset(0).format('YYYY-MM-DD')).utcOffset(0),
        FechaLimite: m.clone().add(7, 'days'),
        HotelCliente:'',
        Idioma: '',
        Moneda: monedaUsuario ?? '', 
        Observaciones: '',
        Cliente: '',
        Comisionista: null,
    }


    const handleSubmit = (values, {setSubmitting}) => {
        const payload = {
            "HotelCliente": values.HotelCliente,
            "PorcDescuento": 0,
            "PresupuestoEnviado": false,
            "ReservaPagada": false,
            "Observaciones": values.Observaciones??'',
            "IvaFactura": 0,
            "ImporteFactura": 0,
            "VersionFila": 0,
            "FkUsuarios": loggedInUser,
            "FkClientes": values.Cliente,
            "FechaVenta": values.FechaPresupuesto,
            "FechaLimitePresupuesto": values.FechaLimite,
            "Idioma": values.Idioma,
            "Moneda": values.Moneda,
          }
          if (values.Comisionista) {
            console.log('values.Comisionista');
            console.log(values.Comisionista);
            payload.FkComisionistas = values.Comisionista;
          }
          if( orderLines.length === 0){
              Swal.fire('Error', 'No puede crear una venta sin agregar servicios', 'error').then( () => {
                  setSubmitting( false )
              })
              return;
          }
          
        dispatch(actionAddInvoice( payload, setSubmitting ))
    }

    return (
        <Layout>

            <Box
                component="main"
                sx={{
                flexGrow: 1,
                marginTop: '1rem',
                }}
            >
            <Container maxWidth={false}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <Formik initialValues={ initialValues } validationSchema={ validationSchemaBudget } onSubmit={ handleSubmit }>
            {({ handleChange, errors, handleBlur, isSubmitting, touched, isValid, setFieldValue, values }) => (
            
            
            <Form >
            <Grid container spacing={3}>
                <Grid item
                    lg={3}
                    xl={3}
                    xs={12}
                >
                    <Typography variant="overline" gutterBottom>Presupuesto para</Typography>
                                        

                    <Grid item>
                        <Autocomplete style={{fontSize: '0.8em'}}
                                name='Cliente'
                                autoHighlight
                                fullWidth
                                disablePortal
                                ListboxProps={{ style: { maxHeight: '10rem' } }}
                                noOptionsText={ `No hay clientes` }
                                id="client-autocomplete"
                                renderInput={(params) => ( 
                                    <TextField 
                                        {...params}
                                        name='Cliente' 
                                        variant='outlined' 
                                        label="Localizar cliente" 
                                        placeholder='Busca...'
                                        disabled={ isSubmitting }
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        error={touched.Cliente && Boolean(errors.Cliente)}
                                        helperText={touched.Cliente && errors.Cliente}

                                    />)}
                                //onInputChange={ idRol === ROLADMIN ? debounceSearchResultClientes : debounceSearchResultClientesNotAdmin}
                                sx={{ marginBottom: '0.2rem'}}
                                options= { clientsArray } //{ idRol === ROLADMIN ? searchClientes : searchClientesNotAdmin }                                
                                onChange={(_, value) => {                                    
                                    setFieldValue(
                                    "Cliente",
                                    value?.value !== null ? value?.value : initialValues.Cliente
                                    );
                                }}                    
                        />
                        
                        { !values.Cliente && <Button style={{ backgroundColor: '#404040', color:'#fff', borderRadius: 7, marginTop: '0.2rem'}} onClick={ handleAddClient }><Typography variant={ matchesXS && 'subtitle1'}>NUEVO CLIENTE</Typography></Button>}
                    </Grid>
                    

                    <Grid item style={{textAlign: 'left', marginTop: '1.2rem'}}>                        
                        <Grid item container style={{ display: 'flex', marginTop: '1rem' }}>
                            <Typography style= {{fontSize: '0.8em'}} variant="h3" gutterBottom>HOTEL:</Typography>
                            <TextField 
                                name='HotelCliente' 
                                onChange={ handleChange } 
                                onBlur={ handleBlur } 
                                fullWidth id="standard-basic" 
                                variant="standard" 
                                InputProps={{
                                    classes: { notchedOutline: classes.rangeDate, 
                                        input: classes.resize  }
                                }}
                                disabled={ isSubmitting }
                                error={touched.HotelCliente && Boolean(errors.HotelCliente)}
                                helperText={touched.HotelCliente && errors.HotelCliente}
                            
                            />
                        </Grid>
                        <Grid item  classes={{root: classes.container }}>
                                <Box>
                                    <FormControl
                                        fullWidth
                                        style={{
                                            marginTop: '2rem'
                                        }}
                                        disabled={ isSubmitting }
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        
                                                          
                                    >
                                    
                                    <InputLabel style={{fontSize: '1rem'}} id="select-label-exchange-rates">Idioma</InputLabel>
                                        <Field
                                            name="Idioma" 
                                            component={CustomizedSelect}
                                            >
                                                {
                                                    languages.map( l => {
                                                        return (
                                                            <MenuItem style={{fontSize: '0.7em'}} value={ l.code }>
                                                            <ListItemIcon>
                                                                {
                                                                    l.icon()
                                                                }
                                                            </ListItemIcon>
                                                            {
                                                                l.label
                                                            }
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                                
                                        </Field>
                                    </FormControl>
                                </Box>
                        </Grid> 
                        <Grid item  classes={{root: classes.container }}>
                                <Box>
                                    <FormControl
                                        fullWidth
                                        style={{
                                            marginTop: '1rem'
                                        }}
                                        disabled={ isSubmitting }
                                        onBlur={ handleBlur }
                                        onChange={handleChange}
                                        
                                                          
                                    >
                                    
                                    <InputLabel style={{fontSize: '1rem'}} id="select-label-exchange-rates">Moneda</InputLabel>
                                        <Field
                                            name="Moneda" 
                                            component={CustomizedSelect}
                                            >
                                                {
                                                    currencies.map( c => {
                                                        return (
                                                            <MenuItem style={{fontSize: '0.7em'}} value={ c.code }>
                                                            <ListItemIcon>
                                                                 {
                                                                    c.icon()
                                                                }
                                                            </ListItemIcon>
                                                            {
                                                                c.label
                                                            }
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                                
                                        </Field>
                                    </FormControl>
                                </Box>
                        </Grid>   

                            
{(idRol === ROLADMIN || idRol === ROLVENDEDOR) &&
                        
                        <Grid item  classes={{root: classes.container }}>
                            <Autocomplete style={{fontSize: '0.8em'}}
                                    name='Comisionista'
                                    autoHighlight
                                    fullWidth
                                    disablePortal
                                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                                    noOptionsText={ `No hay comisionistas` }
                                    id="client-autocomplete"
                                    renderInput={(params) => ( 
                                        <TextField 
                                            {...params}
                                            name='Comisionista' 
                                            variant='outlined' 
                                            label="Localizar comisionista" 
                                            placeholder='Busca...'
                                            disabled={ isSubmitting }
                                            onBlur={ handleBlur }
                                            onChange={handleChange}
                                            error={touched.Comisionista && Boolean(errors.Comisionista)}
                                            helperText={touched.Comisionista && errors.Comisionista}

                                        />)}
                                    //onInputChange={ idRol === ROLADMIN ? debounceSearchResultClientes : debounceSearchResultClientesNotAdmin}
                                    sx={{ marginBottom: '0.2rem'}}
                                    options= { comisionistasArray } //{ idRol === ROLADMIN ? searchClientes : searchClientesNotAdmin }                                
                                    onChange={(_, value) => {                                    
                                        setFieldValue(
                                        "Comisionista",
                                        value?.value !== null ? value?.value : initialValues.Comisionista
                                        );
                                    }}                    
                            />
                            
                        </Grid>
                        
                                }


                    </Grid>
                    
       
            </Grid>
            
                <Grid item
                    lg={9}
                    xl={9}
                    xs={12}
                >

                   <div className={styles.addButton}>
                        <Button style={{ backgroundColor: '#404040', color:'#fff', borderRadius: 7}} onClick={ handleAddRowService}><Typography variant={ matchesXS && 'subtitle1' }>AÑADIR SERVICIO</Typography></Button>
                        {
                            (idRol === ROLVENDEDOR || idRol === ROLADMIN)
                            &&
                            (
                                <Button style={{ backgroundColor: '#404040', color:'#fff', borderRadius: 7, marginLeft: 10}} onClick={ handleAddRowCustomService }><Typography variant={ matchesXS && 'subtitle1' }>AÑADIR LÍNEA LIBRE</Typography></Button>
                            )
                        }

                    </div>

                   <Table aria-label="simple table" style={{ marginTop: '1rem'}}>
                        <TableHead>
                            <TableRow classes={ styles.tableHead }>
                                <TableCell style={{width:'40%'}}><Typography variant='h4'>Servicio</Typography></TableCell>
                                {
                                    !matchesXS
                                    &&
                                    (
                                        idRol !== ROLREVENDEDOR
                                        &&
                                        (
                                            <TableCell style={{width:'20%'}}><Typography variant='h4'>Proveedor</Typography></TableCell>
                                        )
                                    )
                                   
                                }
                                <TableCell  style={{width:'15%'}}><Typography variant='h4'>Fecha</Typography></TableCell>
                                <TableCell><Typography variant='h4'>PAX</Typography></TableCell>
                                {
                                    (idRol === ROLREVENDEDOR )
                                    &&
                                    (
                                        <TableCell><Typography variant='h4'>Neto/PAX</Typography></TableCell>
                                    )
                                }
                                {
                                    (idRol === ROLREVENDEDOR )
                                    &&
                                    (
                                        <TableCell><Typography variant='h4'>Margen/PAX</Typography></TableCell>
                                    )
                                }
                                {
                                    !matchesXS
                                    &&
                                    <TableCell><Typography variant='h4'>PVP/PAX</Typography></TableCell>
                                }
                                {
                                    !matchesXS
                                    &&
                                <TableCell><Typography noWrap variant='h4'>PVP Total </Typography></TableCell>
                                }   
                                <TableCell><Typography variant='h4'>&nbsp;</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                            <TableBody>
                            {orderLines.map((itemField, index) => {
                                return (
                                    <TableRow style={{ cursor: 'pointer' }} key={index} onClick={(e) =>handleEditLineState(index, e)}>
                                        <TableCell align="left"  scope="row" >     
                                            <InputBase style={{fontSize: '0.8em', width: '100%'}} sx={{ ml: 1, flex: 1 }} type="text" readOnly name="NombreServicio"  value={itemField.Concepto}  />
                                        </TableCell>
                                        {
                                            !matchesXS
                                            &&
                                            (
                                                idRol !== ROLREVENDEDOR
                                                &&
                                                (
                                                    <TableCell align="left" > <InputBase style={{fontSize: '0.8em'}}  sx={{ ml: 1, flex: 1 }} type="text" readOnly name="NombreProveedor"  value={itemField.NombreProveedor } /> </TableCell>
                                                )
                                            )
                                            
                                        }
                                        <TableCell align="right"> <InputBase style={{fontSize: '0.8em'}}  sx={{ ml: 1, flex: 1 }} type="text" readOnly name="Fecha"  value={itemField.FechaRecogida?moment(itemField.FechaRecogida).format('DD/MM/YYYY'): 'Sin seleccionar'} /> </TableCell>
                                        <TableCell align="right"> <InputBase style={{fontSize: '0.8em'}}  sx={{ ml: 1, flex: 1 }} type="number" readOnly name="Unidades"  value={itemField.Unidades} /> </TableCell>
                                        {
                                            !matchesXS
                                            &&
                                            idRol === ROLREVENDEDOR
                                            &&
                                            <TableCell align="right"> <InputBase style={{fontSize: '0.8em'}}  sx={{ ml: 1, flex: 1 }} type="text" readOnly name="Neto/PAX"  
                                            value={
                                                (idRol===ROLREVENDEDOR && monedaUsuario==='USD') ?
                                                 '' + +(+itemField.ReservaLineaDolares + +itemField.InSituLineaDolares - +itemField.ReventaLineaDolares)
                                                    + getSimboloMoneda('USD')
                                                : 
                                                    ''+(Math.round((+itemField.ReservaDescuento || +itemField.ReservaLineaEuros) + 
                                                + +itemField.InSituLineaEuros - +itemField.ReventaLineaEuros))+getSimboloMoneda('EUR')
                                                } /> </TableCell>
                                        }
                                        {
                                        (idRol === ROLREVENDEDOR && orderLines.length > 0)
                                        &&
                                        (
                                            <TableCell align="right"> <InputBase style={{fontSize: '0.8em'}}  sx={{ ml: 1, flex: 1 }} type="text" readOnly name="ImporteReventa"  
                                            value={
                                                '' + ((monedaUsuario ==='EUR') ? 
                                                    +itemField.ReventaLineaEuros :
                                                    +itemField.ReventaLineaDolares)
                                                + getSimboloMoneda(idRol===ROLREVENDEDOR?monedaUsuario:'EUR')
                                                } /> </TableCell>
                                        )
                                        }
                                        {
                                            !matchesXS
                                            &&
                                            <TableCell align="right"> <InputBase style={{fontSize: '0.8em'}}  sx={{ ml: 1, flex: 1 }} type="text" readOnly name="ReservaEuros"  
                                            value={
                                                (idRol===ROLREVENDEDOR && monedaUsuario==='USD') ?
                                                 '' + +(+itemField.ReservaLineaDolares + +itemField.InSituLineaDolares)
                                                    + getSimboloMoneda('USD')
                                                : 
                                                    ''+(Math.round((+itemField.ReservaDescuento || +itemField.ReservaLineaEuros) + 
                                                + +itemField.InSituLineaEuros))+getSimboloMoneda('EUR')
                                                } /> </TableCell>
                                        }
                                        {!matchesXS
                                            &&                                        
                                            <TableCell align="right"> <InputBase style={{fontSize: '0.8em'}}  sx={{ ml: 1, flex: 1 }} type="text" readOnly name="Total"   
                                                value={ 
                                                    (idRol===ROLREVENDEDOR && monedaUsuario==='USD') ?
                                                     '' + +((+itemField.SuplementoTransporteDolares + +itemField.ReservaLineaDolares+ +itemField.InSituLineaDolares)*+itemField.Unidades)
                                                        + getSimboloMoneda('USD')
                                                    : 
                                                    Math.round(+itemField.Unidades * 
                                                        (+itemField.SuplementoTransporteEuros + (+itemField.ReservaDescuento || +itemField.ReservaLineaEuros)+(+itemField.InSituLineaEuros??0)))
                                                        + getSimboloMoneda('EUR') 
                                                    
                                                    } /> </TableCell>
                                        }
                                        <TableCell align="right"> 
                                            <IconButton onClick={(e) =>handleRemoveField(index, e)}>
                                                <DeleteOutlineRounded style={{width: '20px', height: '20px'}}/>
                                            </IconButton>
                                             
                                            
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            </TableBody>
                    </Table>

                           
                    <Grid item style={{textAlign: 'left', marginTop: '1.2rem'}}> 
                        <Grid item container style={{ display: 'flex', marginTop: '1rem' }}>
                            <Typography variant="h2" gutterBottom>TASAS: </Typography>
                            <Typography variant="body2" style={{ marginLeft: '1rem'}}>
                                { Math.round(tasas) || 0 } { ' ' + getSimboloMoneda(idRol===ROLREVENDEDOR?monedaUsuario:'EUR') }
                            </Typography>
                        </Grid> 
                        
                        <Grid item container style={{ display: 'flex', marginTop: '1rem' }}>
                            <Typography variant="h2" gutterBottom>TOTAL: </Typography>
                            <Typography variant="body2" style={{ marginLeft: '1rem'}}>
                                { Math.round(value) || 0 }  { ' ' + getSimboloMoneda(idRol===ROLREVENDEDOR?monedaUsuario:'EUR') }
                            </Typography>
                        </Grid>    
                    </Grid>


                    <div className={styles.addButton}>
                        <Button style={{ backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} onClick={ () => navigate('/sales') }><Typography style={{fontWeight: 'bold'}} variant={ matchesXS && 'subtitle1' }>Cancelar </Typography></Button>
                        <Button style={{ backgroundColor: '#F39868', color:'#fff', borderRadius: 7, marginLeft: 10}} type="submit" disabled={ !isValid || isSubmitting }><Typography variant={ matchesXS && 'subtitle1' }>Guardar y continuar</Typography></Button>

                    </div>

                    <Grid item >
                        <div className={styles.note}>
                            <Typography variant='h4'>Observaciones</Typography>
                            <textarea
                                name='Observaciones'
                                className={ styles.textarea} 
                                placeholder="Añade una descripción"
                                onChange={ handleChange }
                                onBlur={ handleBlur }
                            />
                        </div>
                    </Grid>

                </Grid>
            </Grid>
            
            </Form>
            
            
            )}
            </Formik>
            </LocalizationProvider>
            </Container>
            </Box>

            
            <TransitionsModal />
            <NotificationModal />
        </Layout>
    )
}


export default Invoice;