
//Third-party packages
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startDownloadingVoucher } from '../../redux/actions/sales';


//Custom imports
import { uiManageModalNotification } from '../../redux/actions/ui';
import { Content } from './Content';


/**
 * 
    Author: Francisco
    Date: 10-01-2021
 */
export const ConfirmDownloadVoucher = () => {
    
    const dispatch = useDispatch(); //react-redux hook to be able to dispatch the actions
    const { active: { activeLine } } = useSelector(state => state.lines)
    const handleSuccess = () => {
        dispatch(uiManageModalNotification())
       dispatch(startDownloadingVoucher(activeLine.PkLineasVenta))
    }

    //The HTML is being rendered
    return (
            <Content 
                title={ '¿Estás seguro de que quieres descargar el voucher?' }
                action={ handleSuccess }
            />
    )
}
